<template>
  <div>

    <!-- <h1 class="sr-only">Recent questions</h1> -->
    <!-- {{item}} -->
    <div class="bg-white shadow sm:rounded-lg sm:p-4" v-if="item">
      <div class="flex sm:rounded-lg sm:p-4">
        <div class="flex-shrink-0">
          <img class="h-10 w-10 rounded-full"
            src="https://images.unsplash.com/photo-150794778202-cad84cf45f1d?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
            alt="" @click="selectedjob">
        </div>
        <div class="min-w-0 flex-1">
          <p class="text-sm font-medium text-gray-900">
            <a class="hover:underline">{{ item.title }}</a>
          </p>
          <p class="text-sm text-gray-500">
            <a class="hover:underline" @click="selectedjob">
              <v-icon small class="mr-1">{{ icons.mdiVectorCombine }}</v-icon>
              <span class="sencondary--text">
                Company: &nbsp;{{ (item.external === false) ? item.company.name : item.enterprise }}
              </span>
            </a>
          </p>
          <div class="text-sm text-gray-500">
            <v-icon small class="mr-1">{{ icons.mdiMapMarkerOutline }}</v-icon>
            <span v-if="item.external === false">
              {{ item.location }},( {{ item.country ? item.country.iso : '' }})
            </span>
            <span v-else>
              {{ item.location }}
            </span>
          </div>
          <div class="text-sm text-gray-500" v-if="item.external === false">
            {{ item.periodicity }}
          </div>
          <div class="text-sm text-gray-500">
            <v-icon small class="mr-1">{{ icons.mdiCloseCircle }}</v-icon>
            <span>Deadline: </span>{{ item.expire_at }}
          </div>
        </div>
      </div>

      <v-divider class="subprimary my-4"></v-divider>

      <div class="flex justify-between space-x-8">
        <div class="flex space-x-6">
          <span class="inline-flex items-center text-sm">
            <button type="button" :class="likeBtnStatus + ' filter inline-flex space-x-2'" @click="onPressLikeBtn()">
              <svg class="h-5 w-5" x-description="Heroicon name: mini/hand-thumb-up" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path
                  d="M1 8.25a1.25 1.25 0 112.5 0v7.5a1.25 1.25 0 11-2.5 0v-7.5zM11 3V1.7c0-.268.14-.526.395-.607A2 2 0 0114 3c0 .995-.182 1.948-.514 2.826-.204.54.166 1.174.744 1.174h2.52c1.243 0 2.261 1.01 2.146 2.247a23.864 23.864 0 01-1.341 5.974C17.153 16.323 16.072 17 14.9 17h-3.192a3 3 0 01-1.341-.317l-2.734-1.366A3 3 0 006.292 15H5V8h.963c.685 0 1.258-.483 1.612-1.068a4.011 4.011 0 012.166-1.73c.432-.143.853-.386 1.011-.814.16-.432.248-.9.248-1.388z">
                </path>
              </svg>
              <span class="font-medium">29</span>
              <span class="sr-only">likes</span>
            </button>
          </span>
          <span class="inline-flex items-center text-sm">
            <button type="button" class="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
              <svg class="h-5 w-5" x-description="Heroicon name: mini/chat-bubble-left-ellipsis"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M10 2c-2.236 0-4.43.18-6.57.524C1.993 2.755 1 4.014 1 5.426v5.148c0 1.413.993 2.67 2.43 2.902.848.137 1.705.248 2.57.331v3.443a.75.75 0 001.28.53l3.58-3.579a.78.78 0 01.527-.224 41.202 41.202 0 005.183-.5c1.437-.232 2.43-1.49 2.43-2.903V5.426c0-1.413-.993-2.67-2.43-2.902A41.289 41.289 0 0010 2zm0 7a1 1 0 100-2 1 1 0 000 2zM8 8a1 1 0 11-2 0 1 1 0 012 0zm5 1a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"></path>
              </svg>
              <span class="font-medium text-gray-900">11</span>
              <span class="sr-only">replies</span>
            </button>
          </span>
          <span class="inline-flex items-center text-sm">
            <button type="button" class="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
              <svg class="h-5 w-5" x-description="Heroicon name: mini/eye" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"></path>
                <path fill-rule="evenodd"
                  d="M.664 10.59a1.651 1.651 0 010-1.186A10.004 10.004 0 0110 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0110 17c-4.257 0-7.893-2.66-9.336-6.41zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <span class="font-medium text-gray-900">2.7k</span>
              <span class="sr-only">views</span>
            </button>
          </span>
        </div>
        <div class="flex text-sm">
          <span class="inline-flex items-center text-sm">
            <button type="button" class="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
              <svg class="h-5 w-5" x-description="Heroicon name: mini/share" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path
                  d="M13 4.5a2.5 2.5 0 11.702 1.737L6.97 9.604a2.518 2.518 0 010 .792l6.733 3.367a2.5 2.5 0 11-.671 1.341l-6.733-3.367a2.5 2.5 0 110-3.475l6.733-3.366A2.52 2.52 0 0113 4.5z">
                </path>
              </svg>
              <span class="font-medium text-gray-900">Share</span>
            </button>
          </span>
        </div>
      </div>

    </div>
    <template v-else>
      <v-skeleton-loader class="mx-auto pb-2" max-width="100%" type="list-item-two-line" v-for="j in 5" :key="j">
      </v-skeleton-loader>
    </template>
  </div>
</template>
<script>
// mdiVideoInputComponent
import { mdiMagnify, mdiClose, mdiCloseCircle, mdiMapMarkerOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiVectorCombine, mdiOpenInNew } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import { Utils } from '@/plugins/Utils';
export default {
  components: {
  },
  props: {
    item: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      likeBtn: false,
      loaderdata: false,

      submit: true,
    }
  },
  computed: {
    likeBtnStatus() {
      return this.likeBtn ? 'text-green-600' : 'text-gray-400'
    },
  },
  methods: {
    onPressLikeBtn() {
      this.likeBtn = !this.likeBtn
    },
    closeSearch() {
      this.loaderdata = true
      this.loadInitialJob()
    },

    selectedjob() {
      if (this.item.external === false) {
        this.$router.push({ name: 'user.vacancies.details', params: { offer_id: this.item.id, offer_title: Utils.replaceAll(this.item.title.trim(), ' ', '-') } })
      }
      else {
        this.$router.push({ name: 'user.vacancies.external.details', params: { offer_title: this.item.link.trim(), keyword: this.item.keyword.trim() } })
      }
    },
  },
  setup(props) {
    const icons = { mdiClose, mdiCloseCircle, mdiMapMarkerOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiVectorCombine, mdiOpenInNew }
    const utils = { Utils }

    return {
      icons, utils
    }
  }
}
</script>